import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from '@azure/communication-common';
import {
  AvatarPersonaData,
  AzureCommunicationCallAdapterArgs,
  CallAdapterState,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallAdapter,
} from '@azure/communication-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IError } from '@guider-global/shared-types';

import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useVideoTokens } from 'hooks/useVideoTokens';
import { useVideos } from 'hooks/useVideos';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  getVideoRoomState,
  setCallCompositePage,
} from 'store/slices/videoRoomSlice';

import { usePicture } from '@guider-global/azure-storage-hooks';
import VideoRoom from 'components/VideoRoom';
import { useRelationships } from 'hooks';

const VideoPage: FC = () => {
  // Local State
  const [error, setError] = useState<IError[]>([]);

  // React Router
  const navigate = useNavigate();
  const { id: sessionId } = useParams();

  // REDUX
  const dispatch = useAppDispatch();
  const videoRoomState = useAppSelector(getVideoRoomState);
  const { transitionSidebar } = videoRoomState;

  // Hooks
  const { getImage, loading: loadingPicture } = usePicture({
    containerName: 'pictures',
  });

  // - Video Session
  const { video: getVideo, getErrorsVideo } = useVideos({
    getSilentlyUrl: `/video/${sessionId}`,
    getSilently: true,
  });

  const errorsVideo = getErrorsVideo() ?? [];
  const videos = getVideo();
  const {
    displayName,
    roomId,
    communicationUserId,
    relationshipId,
    sessionName,
  } = videos[0];

  // Relationship
  const { relationships: getRelationships, isLoadingRelationships } =
    useRelationships({
      getSilently: true,
      getSilentlyUrl: `/relationships/${relationshipId}`,
    });
  const relationships = getRelationships();
  const relationship = relationships.find(
    (relationship) => relationship.id === relationshipId,
  );
  const relationshipProfiles = [
    ...(relationship?.traineeProfiles ?? []),
    ...(relationship?.guideProfiles ?? []),
  ];

  const handleFetchUserImages = async (
    communicationUserId: string,
  ): Promise<AvatarPersonaData> => {
    const profile = relationshipProfiles.find(
      (profile) => profile?.communicationUserId === communicationUserId,
    );
    const user = relationship?.users?.find(
      (user) => user?.id === profile?.userId,
    );

    if (!user) return {};
    const imageUrl = profile?.picture
      ? getImage(profile?.picture)
      : user.picture;
    return { imageUrl };
  };

  // - Users Video Token
  const { videoToken: getVideoToken, getErrorsVideoToken } = useVideoTokens({
    getSilently: true,
    getSilentlyUrl: `/video/token`,
  });
  const videoTokens = getVideoToken();
  const { token } = videoTokens[0];
  const errorsVideoToken = getErrorsVideoToken() ?? [];

  // - CMS
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const sanityBaseLanguageVideo =
    getBaseLanguage().relationships.sessions.sessions_video;
  const {
    end_session_button_label: exitSessionLabel,
    toggle_chat_button_label: chatLabel,
    toggle_goals_button_label: goalsLabel,
    toggle_schedule_session_button_label: scheduleSessionLabel,
  } = sanityBaseLanguageVideo;
  const localeCode = getBaseLanguage().locale_code.current;

  // ACS Call Adapter
  const credential = useMemo(() => {
    try {
      if (!token) return undefined;
      return new AzureCommunicationTokenCredential(token);
    } catch (error) {
      console.warn(error);

      if (error instanceof Error) {
        setError([{ message: error.message }]);
      } else {
        setError([{ message: 'Failed to construct token credential' }]);
      }
      return undefined;
    }
  }, [token]);

  const callAdapterArgs: Partial<AzureCommunicationCallAdapterArgs> =
    useMemo(() => {
      if (!roomId) return {};
      return {
        userId: fromFlatCommunicationIdentifier(
          communicationUserId,
        ) as CommunicationUserIdentifier,
        displayName,
        credential,
        locator: { groupId: roomId },
      };
    }, [communicationUserId, credential, displayName, roomId]);
  const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

  useEffect(() => {
    if (!callAdapter) return;
    const disposeAdapter = (): void => callAdapter?.dispose();

    const handlePageChange = (state: CallAdapterState) => {
      dispatch(setCallCompositePage(state.page));
    };
    callAdapter.onStateChange((state) => {
      handlePageChange(state);
    });
    window.addEventListener('beforeunload', disposeAdapter);
    return () => {
      callAdapter.offStateChange((state) => {
        handlePageChange(state);
      });
      window.removeEventListener('beforeunload', disposeAdapter);
    };
  }, [callAdapter, dispatch, navigate, relationshipId]);

  // Derivations
  const loading =
    isLoadingRelationships() ||
    !token ||
    !roomId ||
    !callAdapter ||
    loadingPicture;

  const errors = [...errorsVideoToken, ...errorsVideo, ...error];

  return (
    <VideoRoom
      sessionTitle={sessionName}
      exitSessionLabel={exitSessionLabel}
      relationshipId={relationshipId}
      scheduleSessionLabel={scheduleSessionLabel}
      goalsLabel={goalsLabel}
      chatLabel={chatLabel}
      callAdapter={callAdapter}
      onFetchUserImages={handleFetchUserImages}
      localeCode={localeCode}
      transitionSidebar={transitionSidebar}
      loading={loading}
      errors={errors}
    />
  );
};

export default VideoPage;
