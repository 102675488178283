// external
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import {
  RelationshipDetails,
  RelationshipsArchivedList,
  RelationshipsList,
} from 'components';

// store

// hooks
import {
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useRelationships,
} from 'hooks';

import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanitySettings,
} from '@guider-global/sanity-hooks';

export const RelationshipsPage: React.FC = () => {
  const [isNewRelationshipLoading, setIsNewRelationshipLoading] =
    useState(false);
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const navigate = useNavigate();
  const { relationshipId } = useParams();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const { settings } = useSanitySettings({});
  useMixpanelEvents({ navigationEventName: 'Relationships' });

  const {
    relationships: getRelationships,
    isLoadingRelationships,
    hasResultsRelationships,
  } = useRelationships({
    getSilently: true,
  });
  const relationships = getRelationships();
  const isLoading = isLoadingRelationships();
  const activeRelationships = useMemo(
    () => relationships.filter((relationship) => !relationship.isConcluded),
    [relationships],
  );

  useMemberships({ getSilently: true });

  useEffect(() => {
    setIsNewRelationshipLoading(true);

    const timer = setTimeout(() => {
      setIsNewRelationshipLoading(false);
    });

    return () => clearTimeout(timer);
  }, [relationshipId]);

  const currentRelationship = useMemo(
    () =>
      relationships.find((relationship) => relationship.id === relationshipId),
    [relationships, relationshipId],
  );

  const currentRelationshipArchived = currentRelationship?.isConcluded;

  useEffect(() => {
    if (
      !isLoading &&
      relationshipId &&
      !currentRelationship &&
      hasResultsRelationships()
    ) {
      navigate('/relationships');
    }
  }, [
    currentRelationship,
    hasResultsRelationships,
    isLoading,
    navigate,
    relationshipId,
  ]);

  const firstActiveRelationshipId = activeRelationships.at(0)?.id;
  useEffect(() => {
    if (isLoading) return;
    if (relationshipId) return;
    if (isMobile) return;
    if (!firstActiveRelationshipId) return;
    navigate(`./${firstActiveRelationshipId}`);
  }, [
    isLoading,
    relationshipId,
    firstActiveRelationshipId,
    isMobile,
    navigate,
  ]);

  const archivedRelationships = useMemo(
    () => relationships.filter((relationship) => relationship.isConcluded),
    [relationships],
  );

  const renderSidebar = useCallback(() => {
    if (!isMobile || (!relationshipId && relationships.length !== 0)) {
      return (
        <Box
          sx={{
            position: 'relative',
            zIndex: 11,
            width: isMobile ? '100%' : '260px',
            minWidth: isMobile ? '100%' : '260px',
            backgroundColor: '#F5F5F5',
            p: 3,
            borderRight: `1px solid ${theme.palette.divider}`,
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
            {baseLanguage.relationships.relationships_list.title}
          </Typography>
          <RelationshipsList
            relationships={activeRelationships}
            isLoading={isLoading}
          />
          <RelationshipsArchivedList
            relationships={archivedRelationships}
            isLoading={isLoading}
          />
        </Box>
      );
    }
  }, [
    activeRelationships,
    archivedRelationships,
    baseLanguage.relationships.relationships_list.title,
    isLoading,
    isMobile,
    relationshipId,
    relationships.length,
    theme.palette.divider,
  ]);

  const renderNoRelationships = useCallback(() => {
    if (isLoading) return;
    if (currentRelationshipArchived) return;

    if (
      (!isMobile && activeRelationships.length === 0) ||
      (isMobile && relationships.length === 0)
    ) {
      return (
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {isMobile && (
            <Typography variant="h6" component="h1" sx={{ mt: 3, ml: 2 }}>
              {baseLanguage.relationships.relationships_list.title}
            </Typography>
          )}
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: isMobile ? 2 : 0,
            }}
          >
            <Box
              sx={{ width: '280px' }}
              component="img"
              src={buildSanityImageUrl({
                source:
                  settings?.static_media.relationships
                    .empty_state_illustration ?? '',
              })}
            />
            <Typography variant="h5" component="h2" sx={{ mt: 3, mb: 0.75 }}>
              {baseLanguage.relationships.no_relationships_state.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, textAlign: 'center' }}
            >
              {baseLanguage.relationships.no_relationships_state.description}
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                data-cy="pages_RelationshipsPage_programs-button"
                variant="contained"
                onClick={() => navigate('/programs')}
                color="info"
                fullWidth={isMobile}
                sx={{ mr: isMobile ? 0 : 3, mb: isMobile ? 1.5 : 0 }}
              >
                {baseLanguage.globals.programs.view_programs_button_label}
              </Button>
              <Button
                data-cy="pages_RelationshipsPage_learn-button"
                variant="outlined"
                onClick={() => navigate('/learn')}
                color="info"
                fullWidth={isMobile}
              >
                {baseLanguage.globals.common.explore_learning_hub}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
  }, [
    activeRelationships.length,
    baseLanguage.relationships.no_relationships_state.description,
    baseLanguage.relationships.no_relationships_state.title,
    baseLanguage.globals.common.explore_learning_hub,
    baseLanguage.globals.programs.view_programs_button_label,
    baseLanguage.relationships.relationships_list.title,
    currentRelationshipArchived,
    isLoading,
    isMobile,
    navigate,
    relationships.length,
    settings?.static_media.relationships.empty_state_illustration,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        maxHeight: { xs: '100vh', md: 'calc(100vh - 64px)' },
      }}
    >
      {renderSidebar()}

      {currentRelationship && !isNewRelationshipLoading && (
        <RelationshipDetails relationship={currentRelationship} />
      )}

      {renderNoRelationships()}
    </Box>
  );
};

export default RelationshipsPage;
