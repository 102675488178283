import React, { useEffect } from 'react';
// external
import { Box } from '@mui/material';
import { MdCode, MdPerson, MdShield } from 'react-icons/md';
import { BsCalendar } from 'react-icons/bs';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// store
import { RootState } from 'store';

// components
import {
  PageHeader,
  PageSidebar,
  PageWrapper,
  SettingsContainer,
} from 'components';

const mapState = (state: RootState) => {
  return {
    baseLanguage: state.sanityBaseLanguage.baseLanguage,
    organization: state.sanityOrganization.organization,
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const SettingsPageComponent: React.FC<PropsFromRedux> = ({
  baseLanguage,
  organization,
}) => {
  // Router
  const location = useLocation();
  const navigate = useNavigate();
  // Base language
  const { navigation, settings } = baseLanguage;
  const { user_action_menu: userActionMenu } = navigation;
  const {
    communication,
    profile,
    integrations: integrationsBaseLanguage,
    title,
    description,
  } = settings;

  // Organization
  const { white_label: whiteLabel } = organization;
  const { integrations: enabledIntegrations } = whiteLabel;
  const { video_conferencing: videoConferencing, calendar } =
    enabledIntegrations;
  const isIntegrationEnabled =
    videoConferencing.active_directory_teams ||
    calendar.active_directory_outlook_calendar;

  useEffect(() => {
    const isPathIntegration = location.pathname.includes('/integrations');
    if (!isIntegrationEnabled && isPathIntegration) {
      navigate('/settings/profile');
    }
  }, [isIntegrationEnabled, location.pathname, navigate]);

  const integrationSidebardItem = isIntegrationEnabled
    ? [
        {
          primaryText: userActionMenu.integrations_label,
          icon: MdCode,
          path: '/settings/integrations',
          selected: location.pathname.includes('/integrations'),
          dataCyLabel: 'pages_settingsPage_integrations',
        },
      ]
    : [];

  const pageSidebarItems = [
    {
      primaryText: userActionMenu.profile_link_label,
      icon: MdPerson,
      path: '/settings/profile',
      selected: location.pathname.includes('/profile'),
      dataCyLabel: 'pages_settingsPage_profile',
    },
    {
      primaryText: userActionMenu.account_link_label,
      icon: MdShield,
      path: '/settings/account',
      selected: location.pathname.includes('/account'),
      dataCyLabel: 'pages_settingsPage_account',
    },
    ...integrationSidebardItem,
    {
      primaryText: userActionMenu.availability_link_label,
      icon: BsCalendar,
      path: '/settings/availability',
      selected: location.pathname.includes('/availability'),
      dataCyLabel: 'pages_settingsPage_availability',
    },
  ];

  const selectHeader = (location: string): string => {
    const locationIncludesPathname = (pathname: string) =>
      location.includes(pathname);

    if (locationIncludesPathname('/profile'))
      return profile.profile_details.section_title;
    if (locationIncludesPathname('/account')) return '';
    if (locationIncludesPathname('/communication'))
      return communication.section_title;
    if (locationIncludesPathname('/integrations'))
      return integrationsBaseLanguage.section_title;
    return '';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <PageHeader header={title} subheader={description} />
      <PageWrapper sx={{ py: { xs: 0, md: 3 } }}>
        <Box
          sx={{
            width: { xs: '100%', md: '70%' },
            maxWidth: '1440px',
            display: 'flex',
            flexFlow: { xs: 'column nowrap', md: 'row nowrap' },
          }}
        >
          <Box p={{ xs: 2, md: 0 }} pr={{ md: 3 }}>
            <PageSidebar items={pageSidebarItems} sx={{ minWidth: '212px' }} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <SettingsContainer header={selectHeader(location.pathname)}>
              <Outlet />
            </SettingsContainer>
          </Box>
        </Box>
      </PageWrapper>
    </Box>
  );
};

export const SettingsPage = connector(SettingsPageComponent);
