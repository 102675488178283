import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';

import { useAppDispatch } from 'store/hooks';
import useProfileEditForm from 'forms/ProfileForms/ProfileEditForm/hooks/useProfileEditForm';

import { ProfilePictureForm } from 'forms';
import ProfileEditForm from 'forms/ProfileForms/ProfileEditForm';
import { UseProfileEditFormProps } from 'forms/ProfileForms/ProfileEditForm/types';
import { showAppAlert } from 'store/slices/appSlice';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

type SettingsProfilePageProps = UseProfileEditFormProps;

const TIMEOUT = 5000;

export const SettingsProfilePage: FC<SettingsProfilePageProps> = ({
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
  getCustomFieldsSilently = false,
}) => {
  // base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  // Redux
  const dispatch = useAppDispatch();
  // Hooks
  const {
    // React Hook Form
    control,
    handleSubmit,
    errors,
    unregister,
    // Sanity Content
    sanityBaseLanguageProfileFields,
    // Profile
    profile,
    // Organization
    sanityOrganizationProfileFields,
    sanityOrganizationPersonalDetails,
    // Effects
    loading,
    success,
    updateSuccess,
    clearSuccess,
    // Events
    onSubmit,
    onError,
    // Errors/Validation
    editFormErrors,
    isErrors,
    clearIsErrors,
    isDirty,
  } = useProfileEditForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
    getCustomFieldsSilently,
  });

  const successAlertText =
    baseLanguage.settings.profile.profile_details.success_alert_text ??
    'Changes saved successfully';
  const serverAlertText =
    baseLanguage.settings.profile.profile_details.server_error_alert_text ??
    'There was an error saving your changes ';

  useEffect(() => {
    if (isErrors) {
      console.error(editFormErrors);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: serverAlertText,
          timeout: TIMEOUT,
        }),
      );
    }
    const clearErrorState = setTimeout(() => {
      clearIsErrors();
    }, TIMEOUT);

    return () => clearTimeout(clearErrorState);
  }, [isErrors, editFormErrors, dispatch, serverAlertText, clearIsErrors]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(
        showAppAlert({
          severity: 'success',
          message: successAlertText,
          timeout: TIMEOUT,
        }),
      );
      const clearSuccessState = setTimeout(() => {
        clearSuccess();
      }, TIMEOUT);

      return () => clearTimeout(clearSuccessState);
    }
  }, [clearSuccess, dispatch, successAlertText, updateSuccess]);

  return (
    <>
      <Box
        sx={{ display: 'flex', flexFlow: 'column nowrap' }}
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Box
          sx={{
            alignSelf: { sx: 'stretch', md: 'flex-start' },
            minWidth: '50%',
            py: 2,
          }}
        >
          <ProfilePictureForm />
        </Box>
        <ProfileEditForm
          control={control}
          errors={errors}
          unregister={unregister}
          loading={!success || loading}
          baseLanguageProfileFields={sanityBaseLanguageProfileFields}
          profile={profile}
          sanityOrganizationProfileFields={sanityOrganizationProfileFields}
          sanityOrganizationPersonalDetails={sanityOrganizationPersonalDetails}
        />
        <Box
          sx={{
            width: { xs: '100%', md: 'max-content' },
          }}
        >
          <LoadingButton
            fullWidth
            disableElevation
            variant="contained"
            disabled={!isDirty}
            loading={loading}
            size="large"
            sx={{ mt: 2 }}
            type="submit"
            color="info"
            data-cy="pages_settingsProfilePage_saveChanges-button"
          >
            {baseLanguage.globals.common.save_changes_button_label}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default SettingsProfilePage;
