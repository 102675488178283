// external
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { Box, Typography } from '@mui/material';
//components
import { IntegrationsList } from 'components';

export const SettingsIntegrationsPage = () => {
  const { getBaseLanguage } = useSanityBaseLanguage({});

  const baseLanguage = getBaseLanguage();

  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column nowrap', gap: 3 }}>
        <Typography variant="body2" component={'h3'} color="text.secondary">
          {baseLanguage.settings.integrations.section_description}
        </Typography>
        <IntegrationsList />
      </Box>
    </>
  );
};
