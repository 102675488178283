import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { SettingsDescription, SettingsSubheader } from 'components';
import { AvailabilityForm } from 'forms';
import { useAvailabilities } from 'hooks';

export function SettingsAvailabilityPage() {
  const [requestedAvailabilities, setRequestedAvailabilities] = useState(false);

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const { settings } = getBaseLanguage();

  const { availability } = settings;
  const {
    section_title: sectionTitle,
    section_description: sectionDescription,
  } = availability;

  const { reqAvailabilities, isLoadingAvailabilities } = useAvailabilities({});

  useEffect(() => {
    const requestLatestAvailabilities = async () => {
      if (!requestedAvailabilities && !isLoadingAvailabilities()) {
        await reqAvailabilities({ url: '/availabilities' });
        setRequestedAvailabilities(true);
      }
    };

    requestLatestAvailabilities();
  }, [requestedAvailabilities, isLoadingAvailabilities, reqAvailabilities]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        '& > .MuiTypography-h6': {
          mb: 0.5,
        },
      }}
    >
      <SettingsSubheader subheader={sectionTitle} />
      <SettingsDescription description={sectionDescription} />

      <AvailabilityForm />
    </Box>
  );
}
